export default {
  namespaced: true,
  state: {
    menu: [],
  },
  getters: {
    fillterMenu(state) {
      return state.menu
    },
  },
  mutations: {
    UPDATE_MENU(state, val) {
      state.menu = val
    },
  },
  actions: {},
}
