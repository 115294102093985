import root from '../../router/root'
import franchise from '../../router/franchise'
import company from '../../router/company'
import eCommerce from '../../router/services/eCommerce'

import rootNav from '../../navigation/root'
import franchiseNav from '../../navigation/franchise'
import companyNav from '../../navigation/company'
import eCommerceNav from '../../navigation/services/eCommerce'

// Helper function to generate ability actions
const generateAbilityActions = resource => ['read', 'create', 'update', 'delete'].map(action => ({
  subject: resource,
  action,
}))

// Helper function to process navigation and resources
const processResources = (resources, navigation) => {
  const resourceList = []
  const abilityArray = []

  // Process resources
  resources.forEach(item => {
    const { resource } = item.meta || {}
    if (resource && !resourceList.includes(resource)) {
      resourceList.push(resource)
      abilityArray.push(...generateAbilityActions(resource))
    }
  })

  // Process navigation
  navigation.forEach(navItem => {
    const { resource, children } = navItem
    if (resource && !resourceList.includes(resource)) {
      resourceList.push(resource)
      abilityArray.push(...generateAbilityActions(resource))
    }
    if (Array.isArray(children)) {
      children.forEach(child => {
        if (!resourceList.includes(child.resource)) {
          resourceList.push(child.resource)
          abilityArray.push(...generateAbilityActions(child.resource))
        }
      })
    }
  })

  return abilityArray
}

// Root ability
export const rootAbility = () => [
  // { subject: 'all', action: 'manage' },
  ...processResources(root, rootNav),
]

// Franchise ability
export const franchiseAbility = () => {
  const franchiseResources = franchise.filter(
    item => item.meta.resource !== 'Auth',
  )
  const franchiseNavFiltered = franchiseNav.filter(
    item => item.resource !== 'Auth',
  )
  return processResources(franchiseResources, franchiseNavFiltered)
}

// Company ability
export const companyAbility = () => {
  const companyResources = company.filter(
    item => item.meta.resource !== 'Auth',
  )
  const companyNavFiltered = companyNav.filter(
    item => item.resource !== 'Auth',
  )
  return processResources(companyResources, companyNavFiltered)
}

// E-commerce ability
export const eCommerceAbility = () => processResources(eCommerce, eCommerceNav)

// Initial ability
export const initialAbility = []
